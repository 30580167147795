<template>
  <div class="exerover">
    <div class="top">
      <van-icon class="iconback" name="arrow-left" @click="gohome" color="#fff" size="20" />
      <van-circle class="Ratemore" v-model="currentRate" :rate="rate" color="#fff" :stroke-width="80" :speed="100"/>
      <p class="qubi">{{rate + 15}}%</p>
      <p class="rightnoe">正确率</p>
      <div class="textall fl-between">
        <div class="box">
          <p class="title">{{time}}</p>
          <p class="text">练习时间</p>
        </div>
        <div class="box">
          <p class="title">{{testerror}}</p>
          <p class="text">我的错题</p>
        </div>
        <div class="box">
          <p class="title">{{alltest}}</p>
          <p class="text">本次答题数</p>
        </div>
      </div>
    </div>
    <div class="lookerror fl-center">
      <div class="lookcenter" @click="lookerror">
        <div class="lookleft fls">
          <van-icon name="records" size="25" color="#0775F6" />
          <p class="looktitle">查看错题</p>
        </div>
        <van-icon name="arrow" size="20" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      currentRate: 50,
      rate: 30,
      img1: require("../assets/image/resultbg.png"),
      img2: require("../assets/image/resultbg1.png"),
      isshow: true,
      alltest: 32,
      testerror: 16,
      time: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.$route.query);
    this.alltest = parseInt(this.$route.query.benum)
    this.testerror = parseInt(this.$route.query.errornum)
    const second = this.$route.query.time
    const minute = this.$route.query.time
    this.time = `${Math.floor(second / 60 % 60) >= 10 ? Math.floor(second / 60 % 60) : `0${Math.floor(second / 60 % 60)}`} : ${Math.floor(second % 60) >= 10 ? Math.floor(second % 60) : `0${Math.floor(second % 60)}`}`
    this.rate = this.alltest === 0 ? -15 : ((this.alltest - this.testerror) / this.alltest).toFixed(2) * 100 - 15
  },
  mounted() {},
  methods: {
    gohome() {
      this.$router.push('/')
    },
    lookerror() {
      const arr = JSON.parse(localStorage.getItem('errortixing'))
      if (arr.length === 0) {
        this.$router.push('/')
      } else {
        this.$router.push({
          path:'/exercise',
          query: {
            id: 'error'
          }
        })
      }
    }
  },
};
</script>

<style scoped lang="scss">
.exerover {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba($color: #000000, $alpha: 1.0);
  .top {
    width: 375px;
    height: 311px;
    background-size: 100% 100%;
    position: relative;
    background: url("../assets/image/resultbg1.png");
    .iconback{
      position: absolute;
      left: 20px;
      top: 20px;
    }
    .Ratemore{
      width: 116px;
      height: 116px;
      position: absolute;
      top: 70px;
      left: 127px;
      transform: rotate(-115deg);
      ::v-deep.van-circle__layer{
        stroke: none;
      }
    }
    .qubi{
      font-size: 32px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 46px;
      position: absolute;
      top: 99px;
      left: 154px;
    }
    .rightnoe{
      position: absolute;
      top: 149px;
      left: 169px;
      font-size: 14px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }
    .textall{
      width: 226px;
      height: 39px;
      position: absolute;
      top: 210px;
      left: 75px;
      .box{
        .title{
          font-size: 18px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 16px;
          text-align: center;
        }
        .text{
          font-size: 12px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.5);
          line-height: 17px;
          padding-top: 6px;
        }
      }
    }
  }
  .lookerror{
    width: 351px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(160, 160, 160, 0.4);
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    margin-top: 27px;
    .lookcenter{
      width: 303px;
      height: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lookleft{
        .looktitle{
          padding-left: 4px;
          font-size: 18px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #171717;
          line-height: 26px;
        }
      }
    }
  }
}
</style>
